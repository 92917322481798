.coming-soon {
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .coming-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 569px;
        width: 100%;
        margin: 0 auto;

        h4 {
            color: #013159;
            text-align: center;
            font-size: 60px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 150%;
            margin-top: 24px;
            margin-bottom: 30px;
        }

        h6 {
            color: #013159;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 120%;
            margin-bottom: 25px;
        }

        .option-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;

            input {
                border-radius: 100px;
                background: #FFF;
                border: 1px solid transparent;
                padding: 16px;
                color: #013159;
                font-size: 14px;
                font-style: normal;
                font-family: "gregular";
                line-height: 120%;
                width: 100%;
                flex: 1;
                transition: 0.3s linear;

                &::placeholder {
                    color: #627F97;
                }

                &:focus-visible {
                    outline: none;
                }

                &.errorr {
                    border: 1px solid #FE0000 !important;
                }
            }



            .btn-join {
                border-radius: 100px;
                background: #013159;
                padding: 15px 25px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 100%;
                max-width: 157px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }
    }
}

@media (max-width:600px){
    .coming-soon .main-content h4{
        font-size: 30px;
    }
    .coming-soon .main-content h6{
        font-size: 18px;
    }
    .coming-soon .main-content .option-field{
        flex-direction: column;
    }
    .coming-soon .main-content .option-field .btn-join{
        max-width: 100%;
    }
}