.whatwedo {
    background: #F3F3F3;
    border-top: 1px solid rgba(1, 49, 89, 0.05);
    border-bottom: 1px solid rgba(1, 49, 89, 0.05);


    .main-heading {
        border-bottom: 1px solid rgba(1, 49, 89, 0.05);
        background: #F3F3F3;
        padding: 62px 0px;

        h6 {
            color: #013159;
            font-size: 35px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 120%;
        }

    }

    .bottom-content {
        .parent {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .single-box {
                padding: 50px 60px;
                border-right: 1px solid rgba(1, 49, 89, 0.05);

                &:first-child {
                    padding-left: 0;
                }

                &:last-child{
                    border-right: 1px solid transparent;
                }

                h6 {
                    color: #013159;
                    font-size: 20px;
                    font-style: normal;
                    font-family: "gmedium";
                    line-height: 120%;
                    margin-top: 40px;
                    margin-bottom: 20px;
                }

                p {
                    color: #627F97;
                    font-size: 12px;
                    font-style: normal;
                    font-family: "gmedium";
                    line-height: 150%;
                }
            }
        }
    }
}

.powerd-by {
    border-bottom: 1px solid rgba(1, 49, 89, 0.05);
    background: #F3F3F3;
    padding: 80px 0px;
    position: relative;
    z-index: 99;
    .left-bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
    }

    .right-bg{
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        pointer-events: none;
    }

    .main-content {
        max-width: 612px;
        width: 100%;
        margin: 0 auto;
    }

    h5 {
        color: #013159;
        text-align: center;
        font-size: 35px;
        font-style: normal;
        font-family: "gsemibold";
        line-height: 120%;
        margin-bottom: 20px;
    }

    p {
        color: #627F97;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-family: "gregular";
        line-height: 130%;
    }
}

@media (max-width:600px){
    .whatwedo .main-heading h6{
        font-size: 26px;
    }
    .whatwedo .main-heading{
        padding: 40px 0;
    }
    .whatwedo .bottom-content .parent{
        overflow-x: auto;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .whatwedo .bottom-content .parent .single-box{
        width: 300px;
        padding: 30px;
    }
    .powerd-by h5{
        font-size: 26px;
    }
    .powerd-by{
        padding: 60px 0;
    }
    .powerd-by .left-bg{
        display: none;
    }
    .powerd-by .right-bg{
        display: none;
    }
}

@media (max-width:380px){
    .whatwedo .bottom-content .parent .single-box{
        width: 285px;
    }
}