.main-navbar {
    min-height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 20px 0px;


    .navbar-toggler {
        border: none;
        box-shadow: none;

    }


    .btn-connectwallet {
        border-radius: 100px;
        background: #013159;
        padding: 10px 16px;
        color: var(--White, #FFF);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    .dropdown {
        .dropdown-toggle {
            border-radius: 100px;
            background: #FEFEFE;
            border: 1px solid transparent;
            padding: 10px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
            color: var(--White, #013159);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: capitalize;
            margin: 0 !important;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
            }

            &::after {
                display: none;
            }

            &[aria-expanded="true"] {
                border-radius: 100px;
                border: 1px solid #013159;
                background: #FEFEFE;

                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .dropdown-menu {
            padding: 0;
            border: none;
            margin-top: 0px;
            left: unset !important;
            right: 0 !important;
            border-radius: 20px;
            padding: 0px;
            min-width: 315px;
            border-radius: 8px;
            border: 1px solid #013159;
            background: #FEFEFE;
            margin-top: 10px;

            .bg-menu-blur {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                pointer-events: none;
            }




            .upper-items {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                padding: 15px;
                border-bottom: 1px solid #E0E5E9;


                .left-side {

                    .profile {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 12px;

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        h6 {
                            color: #013159;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }
                    }
                }

                .btn-disconnect {
                    border-radius: 34px;
                    background: rgba(228, 76, 73, 0.10);
                    padding: 5px 10px;
                    color: var(--Secondary-Red, #E44C49);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 113%;
                }
            }

            .bottom-links {
                .single-link {
                    border-radius: 8px;
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #E0E5E9;

                    &:last-child {
                        border-bottom: 1px solid transparent;
                    }

                    .text {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 12px;

                        h6 {
                            color: #013159;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }
                    }
                }
            }

        }
    }

}






.mobilemenu-offcanvas {
    width: 100% !important;
    background: #000;

    .offcanvas-header {
        .btn-close {
            // background: url("../../../assets/btn-close-offcanvas.svg") !important;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 1 !important;
            box-shadow: none !important;
        }
    }

    .offcanvas-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .off-dropdown-menu {
        padding: 0;
        border: none;
        margin-top: 19px;
        left: unset !important;
        right: 0 !important;
        border-radius: 20px;
        padding: 8px;
        min-width: 315px;
        background: linear-gradient(103.06deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.003) 110.97%), rgba(29, 29, 29, 0.05);
        backdrop-filter: blur(35.1px);
        width: 100%;

        .bg-menu-blur {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            pointer-events: none;
        }


        &::after {
            content: '';
            position: absolute;
            inset: 0px;
            border-radius: inherit;
            background-color: #ffffff;
            -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            border: 1px solid transparent;
            background: -o-linear-gradient(#111111 0 0) padding-box, -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
            background: linear-gradient(#111111 0 0) padding-box, linear-gradient(185.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
            z-index: -1;
        }

        .upper-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
            padding: 15px;


            .left-side {

                .profile {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 12px;

                    img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    h6 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }
            }

            .btn-disconnect {
                border-radius: 34px;
                background: rgba(228, 76, 73, 0.10);
                padding: 5px 10px;
                color: var(--Secondary-Red, #E44C49);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 113%;
            }
        }

        .bottom-links {
            .single-link {
                border-radius: 8px;
                padding: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;

                    h6 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }
            }
        }

    }

    .btn-disconnect-bottom {
        border-radius: 80px;
        border: 1px solid #F00;
        padding: 12px 16px;
        color: #F00;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .btn-connectwallet {
        border-radius: 100px;
        border: 1px solid var(--Accent, #FF0083);
        padding: 10px 16px;
        color: var(--White, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:600px) {
    .main-navbar .dropdown .dropdown-toggle {
        font-size: 14px;
    }

    .d-noneinmobile {
        display: none;
    }

    .d-blockinmobile {
        display: block !important;
    }

    .main-navbar .btn-connectwallet {
        color: #013159;
        font-size: 14px;
        font-style: normal;
        font-family: "gsemibold";
        line-height: 100%;
        background-color: transparent;
        border: none;

        span {
            color: #013159;
            font-size: 14px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 100%;
        }
    }
    .metamaskhide-mbl{
        display: none !important;
    }
}


