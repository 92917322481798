@font-face {
  font-family: 'gregular';
  src: url('../src/fonts/Galxe-Lader-Regular.ttf');
}

@font-face {
  font-family: 'gmedium';
  src: url('../src/fonts/Galxe-Lader-Medium.ttf');
}

@font-face {
  font-family: 'gsemibold';
  src: url('../src/fonts/Galxe-Lader-SemiBold.ttf');
}

html {
  scroll-behavior: smooth;
}

body {
  background: #F3F3F3;
  min-height: 100vh;
  font-family: 'Galaxy', sans-serif;
  --wcm-z-index: 9999 !important;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.cursor-pointer {
  cursor: pointer;
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

.custom-container {
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}



.modal-backdrop {
  opacity: 0 !important;
}

.connectwallet-modal {
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(7px);

  .modal-dialog {
    max-width: 480px;
  }

  .modal-content {
    position: relative;
    border-radius: 10px;
    background: #F3F3F3;
    padding: 25px;


  }

  .modal-header {
    padding: 0;
    border: none;
    padding-bottom: 25px;

    .modal-title {
      color: #013159;
      font-size: 18px;
      font-style: normal;
      font-family: "gmedium";
      line-height: 120%;
    }

    .btn-close {
      background: url("../src/assets/btn-close.svg") !important;
      opacity: 1 !important;
      box-shadow: none;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .modal-body {
    padding: 0;

    .connectwallet-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 13px;
      width: 100%;
      border-radius: 20px;
      border: 1px solid #E0E5E9;
      background: rgba(255, 255, 255, 0.03);
      padding: 40px 10px;
      color: #013159;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "gmedium";
      line-height: normal;

      transition: 0.3s linear;

      &:hover {
        border-radius: 20px;
        border: 1.5px solid #013159;
        background: rgba(255, 255, 255, 0.03);
      }

    }
  }
}

@media (max-width:600px) {
  .connectwallet-modal .modal-body .connectwallet-btns {
    flex-direction: column;
  }

  .showinputtypedate-text {
    // display: block !important;
  }
}

input[type='date'],
input[type='time'],
input[type='datetime-local'] {
  -webkit-appearance: none;
  text-transform: uppercase;
}

.loader-text {
  color: #FFF;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-family: "gsemibold";
  line-height: 130%;
  margin-top: 25px;
  max-width: 310px;
  width: 100%;
}

.ql-editor.ql-blank::before {
  color: #627F97 !important;
  font-family: "gmedium";
  font-size: 12px;
  font-style: normal;
  line-height: 120%;
  font-style: normal !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.opac05 {
  pointer-events: none;
  opacity: 0.5;
}

.dont-foreget-text {
  color: #627F97;
  font-size: 14px;
  font-style: normal;
  font-family: "gregular";
  line-height: 129%;
  letter-spacing: -0.28px;
}

.wcm-overlay {
  z-index: 999999999999 !important;
}

.wcm-active {
  pointer-events: auto;
  position: relative !important;
  z-index: 9999999999 !important;
}

.forpad {
  margin-top: 18px;
}

.mainheadingggg {
  margin-top: 30px;

  h2 {
    color: #013159 !important;
    font-size: 22px !important;
    font-style: normal;
    font-family: "gmedium";
    line-height: 150%;
  }
}

.forbrdrrrrr {
  padding: 0px 20px;
  border-left: 1px solid #013159;

}

.hhhhhhhhhh {
  top: 2px !important;
}


.forworldmargin {
  margin-bottom: 8px !important;

}

.parentsdiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin-top: 20px;

  .formate {
    .formatehead {
      margin-top: 0px !important;
    }

  }

  .left {
    .locationparaaaaaaa {
      color: #013159 !important;
      font-size: 14px !important;
      font-style: normal;
      font-family: 'gsemibold' !important;
      line-height: 150% !important;
      margin-bottom: 10px !important;
    }

    .boxxxxxx {
      border-radius: 25px;
      background: #FFF;
      padding: 7px 10px;

      p {
        color: #013159 !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-family: 'gsemibold' !important;
        line-height: 150% !important;
        white-space: nowrap !important;
        margin: 0px !important;
      }
    }
  }

}


.custom-checkbox {
  margin-top: 21px;
  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: #627F97;
    font-size: 13px;
    font-style: normal;
    font-family: "gregular";
    line-height: 120%;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    border-radius: 5px;
    border: 1px solid #989898;
    background-color: #FFF;

    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked+label:before {
    background-color: #013159;
  }

  .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}