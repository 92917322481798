.main-banner {
    position: relative;
    padding-top: 366px;
    padding-bottom: 95px;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        pointer-events: none;
        object-fit: cover;
        object-position: top center;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left-side {
            max-width: 541px;
            width: 100%;
            .main-content {
                h4 {
                    color: #013159;
                    font-size: 30px;
                    font-style: normal;
                    font-family: "gsemibold";
                    line-height: 150%;
                }

                p {
                    color: #013159;
                    font-size: 18px;
                    font-style: normal;
                    font-family: "gmedium";
                    line-height: 150%;
                    margin: 20px 0;
                }

                .twice-btns {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    .btn-create {
                        border-radius: 100px;
                        background: #013159;
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        padding: 15px 25px;
                    }

                    .btn-explore {
                        border-radius: 100px;
                        border: 1px solid #013159;
                        color: #013159;
                        font-size: 14px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        padding: 15px 25px;
                    }
                }
            }
        }
        .right-side{
            max-width: 259px;
            width: 100%;
            .banner-links{
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid rgba(1, 49, 89, 0.20);
                    padding: 8px 0;
                    color: #013159;
                    font-size: 16px;
                    font-style: normal;
                    font-family: "gmedium";
                    line-height: 150%;
                    position: relative;
                    transition: padding 0.5s ease-in-out;
                    svg{
                        opacity: 0.5;
                        transition: transform 0.5s ease-in-out , opacity 0.5s ease-in-out;
                    }
                    &:hover{
                        padding: 8px 10px;
                        transition: padding 0.5s ease-in-out;
                        svg{
                            opacity: 1;
                            transition: transform 0.5s ease-in-out , opacity 0.5s ease-in-out;
                            transform: rotate(-30deg);
                        }
                        .borderbottom{
                            width: 100%;
                            transition: width 0.5s ease-in-out;
                            transform-origin: left right;
                        }
                    }
                    .borderbottom{
                        height: 1px;
                        width: 0;
                        background-color: #013159;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        right: 0;
                        z-index: 9;
                        transition: width 0.5s ease-in-out;
                        transform-origin: left right;
                    }
                }
            }
        }
    }
}

.users-data{
    border-top: 1px solid rgba(1, 49, 89, 0.05);
    border-bottom: 1px solid rgba(1, 49, 89, 0.05);
    .parent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .single-card{
            border-right: 1px solid rgba(1, 49, 89, 0.05);
            padding: 60px;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                border-right: 1px solid transparent;
            }

            h2{
                color: #013159;
                font-size: 60px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 150%; 
                margin: 10px 0;
            }
            h6{
                color: #013159;
                font-size: 12px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 150%; 
                text-transform: uppercase;
            }
        }
    }
}

@media (max-width:600px){
    .users-data .parent{
        grid-template-columns: 1fr 1fr;
    }
    .users-data .parent .single-card h2{
        font-size: 20px;
        word-break: break-all;
    }
    .users-data .parent .single-card{
        padding: 40px 31px;
        &:first-child{
            padding-left: 31px;
        }

    }
    .main-banner .banner-bg{
        display: none;
    }
    .main-banner .banner-bg-mbl{
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        pointer-events: none;
        z-index: -1;
    }
    .main-banner {
        padding-top: 392px;
        padding-bottom: 60px !important;
    }
    .main-banner .parent{
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
    .main-banner .parent .left-side .main-content h4{
        font-size: 24px;
    }
    .main-banner .parent .right-side{
        max-width: 100%;
    }
    .users-data{
        background-color: #f9f9f9;
    }
}