.main-footer {
    background: #F3F3F3;
    padding-top: 46px;
    padding-bottom: 20px;

    .mainparentsss {

        display: flex;
        align-items: baseline;
        justify-content: flex-start;

        .left {
            max-width: 377px;
            width: 100%;
            .footerleftbox {
                border-right: 1px solid rgba(1, 49, 89, 0.05);
                background: transparent;
                padding: 46px 30px 20px 40px;
                
                // width: 100%;

                .mainimage {
                    width: 158.463px;
                    height: 45.785px;
                    object-fit: cover;
                    object-position: center;
                    overflow: hidden;

                    .footorimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                p {
                    color: #627F97;
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: 'gregular';
                    font-size: 12px;
                    font-style: normal;
                    margin-top: 18px;
                    margin-bottom: 121px;
                    line-height: 120%;
                    text-transform: capitalize;
                    max-width: 258px;
                    a{
                        color: #627F97;
                        text-decoration: underline !important;
                    }
                }

                h6 {
                    color: #627F97;
                    font-family: 'gregular';
                    font-size: 12px;
                    font-style: normal;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: capitalize;
                }
            }
        }

        .right {

            position: relative;
            flex: 1;

            .lasttext {
               
                display: flex;
                justify-content: flex-end;
                .tomitext {
                    color: #013159;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 7px;
                    font-size: 12px;
                    font-style: normal;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: capitalize;
                    font-family: 'gmedium';
                }

            }

            .parentright {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                margin-bottom: 70px;


                a {
                    color: #013159;
                    font-family: 'gregular';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 120%;
                    display: flex;
                align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    white-space: nowrap;
                  

                    &:last-child {
                        margin-bottom: 0px !important;
                    }
                }

                .first {
                    padding: 46px 114px 27px 40px;
                    background: transparent;
                    // display: inline-flex;
                    // align-items: center;
                    text-align: end;
                }

                .second {
                    // display: inline-flex;
                    padding: 46px 100.25px 27px 39.75px;
                    // align-items: center;
                    // background: transparent;
                    text-align: end;
                }

                .third {
                    padding: 46px 135.5px 27px 40.5px;
                    // align-items: center;
                    // background: transparent;
                    text-align: end;
                }

                .fourth {
                    // display: inline-flex;
                    padding: 46px 77.75px 27px 40.25px;
                    // align-items: center;
                    background: transparent;
                    text-align: end;
                }
            }
        }
    }
}
@media(max-width:1199px){
    .main-footer .mainparentsss .right .parentright{
    grid-template-columns: 1fr 1fr;
    }
    .bottomtext {
        display: block !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;

        .reserved {
            color: #627F97;

            font-family: 'gregular';
            font-size: 12px;
            font-style: normal;

            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
        }

        .tomitext {
            color: #013159;
            font-family: 'gmedium';

            font-size: 12px;
            font-style: normal;
            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 7px;
        }
    }
    .main-footer .mainparentsss .right .lasttext .tomitext{
        display: none !important;
    }
}
@media(max-width:991px) {
    .main-footer .mainparentsss .left .footerleftbox {
        padding: 40px 20px;
        border-right: unset !important;
    }

    .main-footer .mainparentsss .left .footerleftbox p {
        margin-top: 41px;
        margin-bottom: 0px !important;
    }

    .main-footer .mainparentsss .right .parentright .first {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright .second {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright .third {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright .fourth {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright {
        grid-template-columns: 1fr 1fr;
    }

    .main-footer .mainparentsss {
        flex-direction: column;
    }

    .main-footer .mainparentsss .right .lasttext .tomitext {
        display: none;
    }

    .main-footer .mainparentsss .left .footerleftbox h6 {
        display: none;
    }

    .bottomtext {
        display: block !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;

        .reserved {
            color: #627F97;

            font-family: 'gregular';
            font-size: 12px;
            font-style: normal;

            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
        }

        .tomitext {
            color: #013159;
            font-family: 'gmedium';

            font-size: 12px;
            font-style: normal;
            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 7px;
        }
    }
}

@media(max-width:600px) {
    .main-footer .mainparentsss .left .footerleftbox {
        padding: 40px 20px;
        border-right: unset !important;
    }

    .main-footer .mainparentsss .left .footerleftbox p {
        margin-top: 41px;
        margin-bottom: 0px !important;
    }

    .main-footer .mainparentsss .right .parentright .first {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright .second {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright .third {
        padding: 32px 20px;
    }

    .main-footer .mainparentsss .right .parentright .fourth {
        padding: 32px 20px;
        padding-left: 0;
    }

    .main-footer .mainparentsss .right .parentright {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 30px !important;
    }

    .main-footer .mainparentsss {
        flex-direction: column;
    }

    .main-footer .mainparentsss .right .lasttext .tomitext {
        display: none;
    }

    .main-footer .mainparentsss .left .footerleftbox h6 {
        display: none;
    }

    .bottomtext {
        display: block !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        .reserved {
            color: #627F97;

            font-family: 'gregular';
            font-size: 12px;
            font-style: normal;

            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
        }

        .tomitext {
            color: #013159;
            font-family: 'gmedium';

            font-size: 12px;
            font-style: normal;
            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 7px;
        }
    }
    .main-footer .mainparentsss .left .footerleftbox{
        width: 100%;
        padding: 0;
    }
    .main-footer .mainparentsss .right .parentright .first{
        padding: 26px 0;
    }
    .main-footer .mainparentsss .right .parentright .third{
        padding: 26px 0;
    }
    .bottomtext{
        padding-top: 30px;
    }
    .main-footer .mainparentsss .right .parentright{
        align-items: center;
    }
    .main-footer .mainparentsss .left .footerleftbox p{
        max-width: 100%;
    }
    
}