.how-section {
    background: #F3F3F3;
    border-top: 1px solid rgba(1, 49, 89, 0.05);
    border-bottom: 1px solid rgba(1, 49, 89, 0.05);
    position: relative;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left-side {
            max-width: 499px;
            width: 100%;
            
            padding: 62px 0px;
            position: sticky;
            top: 0;

            .main-heading {
                h6 {
                    color: #013159;
                    font-size: 35px;
                    font-style: normal;
                    font-family: "gsemibold";
                    line-height: 120%;
                    margin-bottom: 20px;
                }

                p {
                    color: #627F97;
                    font-size: 12px;
                    font-style: normal;
                    font-family: "gmedium";
                    line-height: 150%;
                }
            }


        }

        .right-side {
            flex: 1;
         

            
            .side-content {
                .single-content {
                    border-bottom: 1px solid rgba(1, 49, 89, 0.05);
                    background: #F3F3F3;
                    padding: 62px 60px;
                    padding-right: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    border-left: 1px solid rgba(1, 49, 89, 0.05);
                    .inner-left{
                        .inside-content{
                            h6{
                                color: #013159;
                                font-size: 12px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 150%; 
                                text-transform: uppercase;
                                span{
                                    color: #627F97;
                                }
                            }
                            h4{
                                color: #013159;
                                font-size: 35px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                margin: 20px 0;
                            }
                            p{
                                color: #627F97;
                                font-size: 12px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 150%; 
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:600px){
    .how-section .parent{
        flex-direction: column;
    }
    .how-section .parent .left-side{
        position: static;
    }
    .how-section .parent .right-side .side-content .single-content{
        padding: 40px 0;
        gap: 30px;
    }
    .how-section .parent .right-side .side-content .single-content .inner-left .inside-content h4{
        font-size: 22px;
    }
    .how-section .parent .right-side .side-content .single-content .inner-right{
        flex-shrink: 0;
    }
    .how-section .parent .right-side .side-content .single-content .inner-right img{
        width: 130px;
        flex-shrink: 0;
    }
    .how-section .parent .right-side .side-content .single-content .inner-left .inside-content p br{
        display: none;
    }
}