.about-section{
    padding: 80px 0;
    position: relative;
    .about-right-img{
        position: absolute;
        top: 60%;
        right: 0;
        transform: translate(0%, -50%);
        pointer-events: none;
        z-index: -1;
    }
    .main-content{
        max-width: 708px;
        width: 100%;
        .subtitle{
            color: #013159;
            font-size: 12px;
            font-style: normal;
            font-family: "gmedium";
            line-height: 150%; 
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        h4{
            color: #013159;
            font-size: 26px;
            font-style: normal;
            font-family: "gregular";
            line-height: 120%; 
            margin-bottom: 60px;
        }
        .bottom-text{
            h6{
                color: #013159;
                font-size: 20px;
                font-style: normal;
                font-family: "gsemibold";
                line-height: 120%; 
                margin-bottom: 34px;
            }
            .parent-multi{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 40px;
                .single-text{
                    h5{
                        color: #013159;
                        font-size: 18px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 120%;
                        margin-bottom: 12px;
                    }
                    p{
                        color: #627F97;
                        font-size: 12px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 150%; 
                    }
                }
            }
        }
    }
}

@media (max-width:600px){
    .about-section{
        padding-top: 60px;
        padding-bottom: 120px;
    }
    .about-section .main-content h4{
        font-size: 24px;
    }
    .about-section .main-content .bottom-text .parent-multi{
        grid-template-columns: 1fr;
    }
    .about-section .about-right-img{
        top: unset;
        bottom: 0;
        transform: translate(0%, 0%);

    }
    .about-section .main-content .bottom-text .parent-multi .single-text{
        &:nth-child(3){
            order: 0;
        }
        &:nth-child(2){
            order: 1;
        }
        &:nth-child(4){
            order: 2;
        }
    }
}