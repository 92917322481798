.latesttask-section {
    padding-top: 118px;
    background: #F3F3F3;

    .breadcrumb-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 3px;

        a {
            color: #627F97;
            font-size: 14px;
            font-style: normal;
            font-family: "gmedium";
            line-height: 150%;
            text-transform: uppercase;

            &.active {
                color: #013159;

            }
        }
    }

    .main-heading .btn-view {
        display: none;
    }

    .main-heading .choice-bar {
        position: static !important;
        transform: unset !important;
    }

    .main-heading {
        background: #F3F3F3 !important;
    }

    .bottom-content .parent-cards .single-card {
        background: #F9F9F9 !important;
        border-bottom: 1px solid rgba(1, 49, 89, 0.05);

    }

    .paginationmain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 20px 14px 20px;
        justify-content: center;
        background: #F9F9F9;

        .paginationtext {
            color: #013159;
            font-family: "gmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
            letter-spacing: 0.1px;
            text-decoration-line: underline;
        }

        .pagination {
            background-color: transparent;
            border: none;
            border-radius: 0;
            border-radius: 9px;
            border: 1px solid #E0E5E9;
            background: #F9F9F9;
            padding: 15px;

            .active>.page-link,
            .page-link.active {
                color: #FFF !important;
                background-color: #013159 !important;
            }

            .page-item {
                .page-link {
                    display: flex;
                    padding: 6px 11px;
                    align-items: center;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    color: #8C8C8C;
                    font-size: 15px;
                    font-style: normal;
                    font-family: "gmedium";
                    line-height: 100%;
                    box-shadow: none;
                    outline: none;
                    margin-left: 2px;

                    &:hover {
                        background-color: #013159 !important;
                        color: #FFF !important;
                    }
                }
            }
            .page-item:first-child .page-link{
                background-color: #013159 !important;
                color: #FFF !important;
            }
            .page-item:last-child .page-link{
                background-color: #013159 !important;
                color: #FFF !important;
            }
          
        }
    }
}

.wrapper-latestsection {
    .main-navbar {
        background: #F3F3F3 !important;
    }
}

.latest-task {
    .main-heading {

        position: relative;
        border-bottom: 1px solid rgba(1, 49, 89, 0.05);
        background: #F9F9F9;
        padding: 30px 0px;

        .parent-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }


        h6 {
            color: #013159;
            font-size: 35px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 120%;
        }

        .btn-view {
            border-radius: 100px;
            background: #013159;
            padding: 12px 20px;
            color: #F9F9F9;
            font-size: 14px;
            font-style: normal;
            font-family: "gmedium";
            line-height: 100%;

        }

        .choice-bar {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 800px;

            .nav {
                background-color: transparent;
                border: none;
                justify-content: flex-start;
                gap: 6px;
                flex-wrap: nowrap;
                
                overflow-x: auto;
                &::-webkit-scrollbar{
                    display: none;
                }

                .nav-item {
                    .nav-link {
                        border-radius: 100px;
                        background: #F0F0F0;
                        padding: 12px 16px;
                        color: #627F97;
                        font-size: 14px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        margin: 0 !important;
                        white-space: nowrap;
                    }
                }
            }

            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
                border-radius: 100px;
                background: #013159;
                color: #FFF;

            }
        }

    }

    .bottom-content {
        background: #F9F9F9;


        .parent-cards {
            .single-card {

                border-bottom: 1px solid rgba(1, 49, 89, 0.05);
                background: #F9F9F9;
                padding: 62px 0px;
                position: relative;
                z-index: 9;
                transition: 0.3s linear;


                .parent {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 228px;
                }

                .right-bg-layer {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                    z-index: -1;
                    pointer-events: none;
                    opacity: 0;
                    transition: 0.3s linear;
                }

                &:hover {

                    .right-bg-layer {
                        opacity: 1;
                    }
                }

                .left-side {
                    max-width: 673px;
                    width: 100%;

                    .main-content {
                        h1,h2,h3,h4,h5,h6 {
                            color: #013159;
                            font-size: 30px;
                            font-style: normal;
                            font-family: "gregular";
                            line-height: 120%;
                            margin-bottom: 20px;
                            // word-break: break-all;
                            strong{
                                font-weight: unset !important;
                            }
                            &:not(:first-child){
                                margin-top: 30px;
                            }
                        }

                        a{
                            color: #013159;
                            text-decoration: underline !important;
                        }

                        p {
                            color: #627F97;
                            font-size: 16px;
                            font-style: normal;
                            font-family: "gregular";
                            line-height: 130%;
                            // word-break: break-all;
                        }
                        ul li{
                            color: #627f97;
                            font-family: "gregular";
                            font-size: 16px;
                            font-style: normal;
                            line-height: 130%;
                            margin: 5px 0;
                            list-style-type: disc;
                            margin-left: 20px;
                            // word-break: break-all;
                        }

                        ol{
                            margin: 0;
                            padding: 0;
                            list-style-position: inside;
                        }
            
                        ol li{
                            color: #627f97;
                            font-family: "gregular";
                            font-size: 16px;
                            font-style: normal;
                            line-height: 130%;
                            margin: 5px 0;
                            // word-break: break-all;
                        }
                    }
                }

                .right-side {
                    flex: 1;

                    .parent-box {
                        border-radius: 14px;
                        border: 1px solid #E0E5E9;
                        background: #F9F9F9;
                        padding: 20px;

                        .upper-area {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 15px;

                            .first-div {
                                p {
                                    color: #627F97;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-family: "gregular";
                                    line-height: 130%;
                                    text-transform: uppercase;
                                    margin-bottom: 10px;
                                }

                                h6 {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 6px;

                                    img {
                                        width: 24px;
                                        height: 24px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                        flex-shrink: 0;
                                    }

                                    color: #013159;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-family: "gmedium";
                                    line-height: 120%;

                                    a{
                                        display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 6px;

                                    img {
                                        width: 24px;
                                        height: 24px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                        flex-shrink: 0;
                                    }

                                    color: #013159;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-family: "gmedium";
                                    line-height: 120%;
                                    }
                                }

                                .multi-imgs {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;

                                    .imgs-parent {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;

                                        img {
                                            width: 24px;
                                            height: 24px;
                                            object-fit: cover;
                                            border-radius: 50%;
                                            margin-left: -10px;

                                            &:first-child {
                                                margin-left: 0;
                                            }
                                        }
                                    }

                                    span {
                                        border-radius: 48px;
                                        border: 0.632px solid #F9F9F9;
                                        background: #FFF;
                                        width: 24px;
                                        height: 24px;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: #013159;
                                        text-align: center;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-family: "gmedium";
                                        line-height: 120%;
                                        margin-left: -10px;
                                    }
                                }
                            }
                        }

                        .mid-timer {
                            border-radius: 9px;
                            background: #FFF;
                            padding: 20px 0px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            gap: 10px;
                            margin: 20px 0;

                            h5 {
                                color: #627F97;
                                font-size: 12px;
                                font-style: normal;
                                font-family: "gregular";
                                line-height: 130%;
                                text-transform: uppercase;
                            }

                            h6 {
                                color: #013159;
                                font-size: 14px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                            }
                        }

                        .btn-part {
                            border-radius: 100px;
                            border: 1px solid #E0E5E9;
                            padding: 15px 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            color: #013159;
                            text-align: center;
                            font-size: 14px;
                            font-style: normal;
                            font-family: "gmedium";
                            line-height: 100%;
                        }

                    }
                }

            }
        }
    }
}

.seeMore{
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    button{
        border-radius: 100px;
    background: #013159;
    padding: 16px 40px;
    color: #F9F9F9;
    font-size: 14px;
    font-style: normal;
    font-family: "gmedium";
    line-height: 100%;
    }
}
@media (max-width:600px) {
    .latest-task .main-heading h6 {
        font-size: 26px;
    }

    .latest-task .main-heading .choice-bar {
        position: static;
        transform: unset;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .latest-task .main-heading .parent-head {
        flex-wrap: wrap;
        gap: 26px;
    }

    .latest-task .main-heading .choice-bar .nav {
        flex-wrap: nowrap;
        padding-bottom: 1px;

    }

    .latest-task .main-heading .choice-bar .nav .nav-item .nav-link {
        white-space: nowrap;
    }

    .latest-task .bottom-content .parent-cards .single-card .parent {
        flex-direction: column;
        gap: 30px;
    }

    .latest-task .bottom-content .parent-cards .single-card .left-side .main-content h6 {
        font-size: 24px;
    }

    .latest-task .bottom-content .parent-cards .single-card {
        padding: 45px 0;
    }
}