.join-section {
    position: relative;
    padding: 100px 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .join-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 100px;
    }

    .main-content {
        max-width: 611px;
        width: 100%;

        h4 {
            color: #013159;
            font-size: 45px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 120%;
            margin-bottom: 20px;
        }

        p {
            color: #627F97;
            font-size: 16px;
            font-style: normal;
            font-family: "gregular";
            line-height: 130%;
            margin-bottom: 40px;
        }

        .twice-btns {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            .btn-create {
                border-radius: 100px;
                background: #013159;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 100%;
                padding: 15px 25px;
            }

            .btn-explore {
                border-radius: 100px;
                border: 1px solid #013159;
                color: #013159;
                font-size: 14px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 100%;
                padding: 15px 25px;
            }
        }
    }

    .join-waitlist {
        flex: 1;

        h6 {
            color: #013159;
            font-size: 22px;
            font-style: normal;
            font-family: "gsemibold";
            line-height: 120%;
            margin-bottom: 25px;
        }

        .option-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            input {
                border-radius: 100px;
                background: #FFF;
                border: 1px solid transparent;
                padding: 16px;
                color: #013159;
                font-size: 14px;
                font-style: normal;
                font-family: "gregular";
                line-height: 120%;
                width: 100%;
                flex: 1;
                transition: 0.3s linear;

                &::placeholder {
                    color: #627F97;
                }

                &:focus-visible {
                    outline: none;
                }

                &.errorr {
                    border: 1px solid #FE0000 !important;
                }
            }

            .error-textt{
                display: none;
            }

            .btn-join {
                border-radius: 100px;
                background: #013159;
                padding: 15px 25px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 100%;
                max-width: 157px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        .error-textt {
            color: #FE0000;
            font-size: 12px;
            font-style: normal;
            font-family: "gmedium";
            line-height: 120%;
            margin-top: 15px;
        }
    }
}

@media (max-width:600px) {
    .join-section .join-bg {
        height: 100%;
    }

    .join-section .main-content h4 {
        font-size: 28px;
    }

    .join-section .parent {
        flex-direction: column;
        gap: 40px;
    }

    .join-section .join-waitlist h6 {
        br {
            display: none;
        }
    }

    .join-section .main-content p {
        margin-bottom: 0;
    }

    .join-section .join-waitlist h6 {
        font-size: 20px;
    }

    .join-section .join-waitlist .option-field {
        flex-direction: column;
        gap: 0px;
    }

    .join-section .join-waitlist .option-field .btn-join {
        max-width: 100%;
        margin-top: 42px;
    }

    .join-section .join-waitlist .error-textt {
        display: none;
    }

    .join-section .join-waitlist .option-field .error-textt {
        display: block !important;
        width: 100%;
    }
}


.joinwait-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg{
        margin-top: 30px;
    }

    h6 {
        color: #013159;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-family: "gmedium";
        line-height: 120%;
        letter-spacing: -0.44px;
        margin: 30px 0;
    }

    a {
        border-radius: 100px !important;
        border: 1px solid #E0E5E9 !important;
        background: #F3F3F3 !important;
        padding: 15px 20px !important;
        color: #013159 !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-family: "gmedium" !important;
        line-height: 100% !important;
    }
}